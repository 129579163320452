"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.atopicDermatitis = void 0;
const cognito_1 = require("../../../model/cognito");
const legal_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/legal-data-guard");
const social_demographics_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/social-demographics-data-guard");
const updated_legal_policy_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/updated-legal-policy-data-guard");
const form_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/form-data-guard");
const profile_inputs_1 = require("../../../model/data-collection/profile/profile-inputs");
const diseases_1 = require("../../../model/diseases");
const languages_1 = require("../../../model/languages");
const lock_1 = require("../../../model/lock/lock");
const pages_1 = require("../../../model/pages");
const user_feedbacks_1 = require("../../../model/user-feedback/user-feedbacks");
const deployment_environments_1 = require("../../deployment-environments");
exports.atopicDermatitis = {
    diseaseType: diseases_1.DiseaseType.DISEASE,
    disease: diseases_1.Disease.ATOPIC_DERMATITIS,
    organisations: [],
    theme: {
        primary: "#3ca66a",
        "primary-focus": "#34915d",
        "primary-content": "#FFFFFF",
        secondary: "#88a9e3",
        "secondary-focus": "#7895c8",
        "secondary-content": "#FFFFFF",
        accent: "#3459A3",
        "accent-focus": "#2D4D8C",
        "accent-content": "#FFFFFF",
        neutral: "#424242",
        "neutral-focus": "#333333",
        "neutral-content": "#FFFFFF",
        "base-100": "#ffffff",
        "base-200": "#f0f0f0",
        "base-300": "#e0e0e0",
        "base-content": "#1f1f1f",
        info: "#25dbf1",
        "info-content": "#082f34",
        success: "#30ca6b",
        "success-content": "#14522a",
        warning: "#dec762",
        "warning-content": "#524b2b",
        error: "#ff4d4d",
        "error-content": "#ffffff",
        "--btn-text-case": "none",
    },
    supportedLanguages: [languages_1.Language.it_IT],
    publicPages: [],
    enabledPages: [
        Object.assign({ txPageName: "chat", route: pages_1.FrontendPageEnum.CHAT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], settings: {
                chatType: pages_1.ChatType.AI,
                introMessagesTx: [],
            }, supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ route: "/your-questions", txPageName: "yourQuestions", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "journey", route: pages_1.FrontendPageEnum.JOURNEY, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "report", route: pages_1.FrontendPageEnum.REPORT, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLocked: "pageLock.report",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
        Object.assign({ route: "/centers", txPageName: "centers", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLocked: "pageLock.centers",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
        Object.assign({ route: "/analysis", txPageName: "analysis", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT] }, (0, lock_1.createPageLock)([
            {
                reasonWhyLocked: "pageLock.dashboard",
                lockType: pages_1.PageLockType.CHAT_COMPLETENESS,
            },
        ])),
        Object.assign({ txPageName: "news-detail", route: pages_1.FrontendPageEnum.NEWS_DETAIL, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.ADMIN,
                cognito_1.SupportedGroupsEnum.USER,
            ], supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "edit-news", route: pages_1.FrontendPageEnum.ADMIN_NEWS, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.ADMIN], supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "edit-news-article", route: pages_1.FrontendPageEnum.ADMIN_NEWS_EDIT, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.ADMIN], supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "admin-signup", route: pages_1.FrontendPageEnum.ADMIN_SIGNUP, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT] }, lock_1.defaultPageLock),
        Object.assign({ route: "/language-notice", txPageName: "language-notice", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.en_US, languages_1.Language.de_DE] }, lock_1.defaultPageLock),
        // {
        //   txPageName: "network-builder",
        //   route: FrontendPageEnum.NETWORK_BUILDER,
        //   groupsWhichCanSeeThePage: [
        //     SupportedGroupsEnum.ADMIN,
        //     SupportedGroupsEnum.USER,
        //   ],
        // },
    ],
    showCallSupportButton: {
        telephoneNumberTx: "disease.atopic-dermatitis.settings.supportNumber",
        helpPageUrl: "disease.atopic-dermatitis.settings.surpportUrl",
    },
    authLogo: "/resources/mama-logo-wide.svg",
    headerLogo: "/resources/mama-logo-wide.svg",
    narrowHeaderLogo: "/resources/mama-logo.svg",
    favIcon: "/resources/mama-favicon.png",
    favIconHighres: "/resources/mama-favicon-highres.png",
    tenantNameTx: "disease.atopic-dermatitis.settings.name",
    autoConfirmUsers: false,
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secrets-atopic-dermatitis-bsQSHW",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secrets-atopic-dermatitis-1POK1w",
    },
    identityProviders: [],
    metaDescriptionTx: "disease.atopic-dermatitis.settings.description",
    dataGuards: {
        signUpAdmin: [legal_data_guard_1.legalDataGuard],
        existingAdmin: [updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard],
        signUpUser: [],
        existingUser: [
            updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard,
            (0, social_demographics_data_guard_1.socialDemographicsDataGuard)([
                { type: form_data_guard_1.FormDataCollectionInputType.NAME },
                { type: form_data_guard_1.FormDataCollectionInputType.EMAIL },
                { type: form_data_guard_1.FormDataCollectionInputType.DATE_OF_BIRTH },
                { type: form_data_guard_1.FormDataCollectionInputType.COUNTRY },
                { type: form_data_guard_1.FormDataCollectionInputType.POSTAL_CODE },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.IS_MEMBER_OF_PAG,
                    isOptional: true,
                },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.ACQUISITION_DETAIL,
                    isOptional: true,
                },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.PHONE_NUMBER,
                    reasonWhyShown: "userData.telephoneNote",
                    isOptional: true,
                },
            ]),
        ],
    },
    userFeedback: [...user_feedbacks_1.defaultUserFeedbacks, ...user_feedbacks_1.followUpUserFeedbacks],
    profileInputs: profile_inputs_1.baseFormDataInputs,
};
